<template>
  <PassosTabSel />
</template>

<script>
import PassosTabSel from "@/components/tabSel/PassosTabSel.vue";

export default {
  components: {
    PassosTabSel
  }
};
</script>
